<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3>Editar Etiqueta</h3>
      </CCardHeader>
      <CCardBody>
        <CTabs variant="pills">
          <CTab title="Datos basicos">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                  class="contact-form"
                  method="post"
                  v-on:submit.prevent="handleSubmit(actualizar)"
              >
                <CCard>
                  <CCardBody>
                    <CRow>
                      <CCol sm="12">
                        <div class="form-group">
                          <label>
                            Etiqueta:
                          </label>
                          <validation-provider
                              name="Etiqueta"
                              :rules="{ required: true }"
                              :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                              v-slot="validationContext"
                          >
                            <input
                                type="text"
                                v-model="registro.etiqueta"
                                class="form-control"
                            />
                            <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                          </validation-provider>
                        </div>
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol sm="12">
                        <div class="form-group">
                          <label>
                            Idioma:
                          </label>
                          <validation-provider
                              name="Idioma"
                              :rules="{ required: true }"
                              :customMessages="{
                                required: 'Es un campo requerido'
                              }"
                              v-slot="validationContext"
                          >
                            <selectIdioma @ActualizandoId="setIdioma" :idiomaId="idiomaId"/>
                            <input type="hidden" v-model="registro.eco_idioma_id"/>
                            <span style="color: red; font-size: small">
                              {{ validationContext.errors[0] }}
                            </span>
                          </validation-provider>
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>

                <div class="form-group">
                  <button type="button" class="btn btn-primary mr-2" @click="actualizar()">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                    Actualizar
                  </button>
                  <router-link to="/ecoEtiquetas/List">
                    <CButton color="secondary">Cancelar</CButton>
                  </router-link>
                </div>
              </form>
            </ValidationObserver>
          </CTab>
          <CTab title="Traducción">
            <ecoEtiquetasTraduccion :eco_etiqueta_id="id"></ecoEtiquetasTraduccion>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import httpService from "@/services/httpService"
import selectIdioma from '@/components/SelectIdioma.vue'
import ecoEtiquetasTraduccion from './components/ecoEtiquetasTraduccion.vue'

export default {
  components: {
    selectIdioma,
    ecoEtiquetasTraduccion
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {
        id: '',
        valor: '',
        etiqueta: ''
      },
      idiomaId: ''
    };
  },

  methods: {
    async getRegistro() {

      this.id = this.$route.params.id;
      if (this.id !== "0") {
        let url = "eco-etiquetas/" + this.id;
        let response = await httpService.get(url);
        let respuesta = response.data;
        this.registro = respuesta.registros[0];
        this.idiomaId = this.registro.eco_idioma_id.toString();
      }
    },
    setIdioma(value) {
      this.registro.eco_idioma_id = value;
    },
    async actualizar() {
      try {

        let url = "eco-etiquetas/" + this.id;
        let response = await httpService.put(url, this.registro);
        this.$vToastify.success("Operación realizada con éxito", "Éxito");
        this.$router.back()
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.getRegistro();
  }
};
</script>